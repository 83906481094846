<template>
  <div >
      <b-navbar variant="light" fixed="top"  toggleable="lg">
        <b-navbar-brand href="#" >粤词</b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
<!--            <b-nav-item @click="showAbout">关于作者            </b-nav-item>-->
            <b-nav-item href="https://qm.qq.com/cgi-bin/qm/qr?k=pDltbHW2fru3mBg74d3gycyEzhp0hGi3&jump_from=webapi" target="_blank" >
              粤语填词交流群：304490679
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>

    <div class="px-3" style="margin-top: 5rem;" >
      <b-img src="icon.png" fluid style="max-width: 40%; height: auto;" alt="粤词网"></b-img>
    </div>

    <b-modal ref="wordInfoModal" title="词语详情">
      <h3> {{selectedWord}}</h3>
      <p>{{ prnc }}</p>
      <p>{{desc}}</p>
    </b-modal>

    <b-modal ref="about" title="关于作者">
      <img src="../../public/author.jpeg" height="200" width="200"/>
      <ul>
        <li>
          <p>教育</p>
          <p>华南理工大学</p>
        </li>
      </ul>
    </b-modal>

    <b-modal ref="tips1056" title="1056填词法">
      <p>
        1056填词法是一种方便填词初学者进行粤语填词的方法，能保证填入的词能契合旋律。
      </p>
      <h5> 如何用1056填词法进行填词？      </h5>
      <h6>      Step 1      </h6>
      <p>实践证明，粤语中1、0、5、6这四个数字可以填入任何旋律中并且保证能合音。
        因此，首先将1、0、5、6四个数字填进你要填词的旋律中。例如"祝你生日快乐"，可以填入"006056"，便可以使词和旋律契合。</p>
      <h6>      Step 2      </h6>
      <p>
        使用上一步中填好的数字进行搜索，再选出你想用的词。
        例如搜索"00"，可得出"姣婆"，"球员"，"龙门"等，搜索"6056"，可以得出"学前教育","卖咸鸭蛋","面红耳热"等。
        因此，"祝你生日快乐"这句旋律，可以填入"姣婆面红耳热"、"球员学前教育"等。
        当然也可以根据自己的实际需要，找到你填词过程中某个缺失的词位。
      </p>
      <h6>      Step 3       </h6>
      <p>
        利用粤词搜索引擎，还可以结合旋律和韵脚，找到既押韵又合音的词。
        例如旋律是6056，同时希望押"已"这个音，可以搜索"已"+"6056"，可以找到"自由变异"、"寓言故事"、"内涵意义"等词语。
      </p>
    </b-modal>


    <div class="container">
      <div class="row">
        <div class="col-md"><!--width is set by this div -->
          <b-input-group>
            <div class="input-group" >
              <b-form-input size="lg" placeholder="搜索押韵词" v-model="keyword" @keyup.enter="search" ></b-form-input>
              <b-form-input size="lg" placeholder="1056字符串" v-model="str1056" @keyup.enter="search" v-show="isShow1056"></b-form-input>
              <div class="input-group-append">
                <button class="btn btn-primary" type="button" @click="search" style="width: 60px">
                  <font-awesome-icon icon="search" size="lg"/>
                </button>
              </div>
            </div>
            <b-input-group style="margin-bottom: .5rem; margin-top:1rem;">
              <b-form-checkbox v-model="isShow1056" >
                匹配旋律（使用<b-link @click="show1056Tips">1056填词法</b-link>）
              </b-form-checkbox>

            </b-input-group>
          </b-input-group><!-- /input-group -->
        </div>
      </div>
    </div>
    <div style="height: 1rem"></div>

    <div class="container" v-if="resultData">
      <b-card no-body>
        <b-tabs card>
          <b-tab :title="item.name" v-for="item in resultData" :key="item.name">
            <b-container >
              <b-row >
                <b-col cols="4" sm="3" md="2" v-for="word in item.data" :key="word">
                  <!-- Only adding this div with a background to highlight the columns -->
                  <b-button @click="openmodal" block variant="outline-secondary" style="margin-bottom: .5rem; margin-top:.5rem; line-height: 3rem; ">
                    {{ word }}
                  </b-button>
                </b-col>
              </b-row>
              <div>
                <b-button :title="item.name" block @click="loadMore" style="margin-bottom: .5rem; margin-top:.5rem; line-height: 2rem; ">更多</b-button>
              </div>
            </b-container>
          </b-tab>
        </b-tabs>

      </b-card>
    </div>

  </div>
</template>

<script>

import axios from 'axios'

export default {
  name: 'WordSearch',
  data(){
    return {
      // baseUrl : "http://127.0.0.1:5003/",
      baseUrl : "http://jyutci.com:5003/",
      resultData:'',
      keyword:'',
      str1056:'',
      prnc:'',
      selectedWord:'',
      desc:'',
      isShow1056:false,
      searchedKeyword:'',
      searchedStr1056:''
    }
  },
  methods:{
    showAbout(){
      this.$refs['about'].show()
    },
    show1056Tips(){
      this.$refs['tips1056'].show()
    },
    loadMore(e){
      console.log(e.target.title)
      let loader = this.$loading.show({
        // Optional parameters
        container: null,
        canCancel: false,
        onCancel: this.onCancel,
        loader: 'dots',
      });

      var lastWord = ""
      for (var x of this.resultData){
        if (x.name == e.target.title){
          lastWord = x.data.slice(-1)
        }
      }
      console.log("lastWord = "+ lastWord)

      let requestUrl = this.baseUrl + "api/rhymeSearch?word="+ this.searchedKeyword+"&startFrom="+lastWord+"&name="+e.target.title+"&numString="+this.searchedStr1056
      console.log(requestUrl)
      axios.get(requestUrl)
          .then(res => {
            console.log(res.data);

            for (var i=0;i<this.resultData.length;i++){
              if (this.resultData[i].name== e.target.title){
                this.resultData[i].data = this.resultData[i].data.concat(res.data[0].data)
              }
            }

            loader.hide()
          })
          .catch(err => {
            console.error(err);
            loader.hide()
          })
    },
    openmodal(e){
      console.log(e.target.innerText)
      let loader = this.$loading.show({
        // Optional parameters
        container: null,
        canCancel: false,
        onCancel: this.onCancel,
        loader: 'dots',
      });

      axios.get(this.baseUrl + "api/getWordInfo/"+ e.target.innerText)
          .then(res => {
            let wordDict = res.data
            this.selectedWord = wordDict["word"]
            let prnc = '';
            wordDict["prncs"].forEach(function (value) {
              prnc = prnc + value + ' '
            });
            this.prnc = prnc
            this.desc = wordDict["desc"]
            loader.hide()
            this.$refs['wordInfoModal'].show()
          })
          .catch(err => {
            console.error(err);
            loader.hide()
          })
    },
    search(){
      console.log("search3")
      this.searchedKeyword = this.keyword
      this.searchedStr1056 = this.str1056

      let loader = this.$loading.show({
        // Optional parameters
        container: null,
        canCancel: false,
        onCancel: this.onCancel,
        loader: 'dots',
      });

      axios.get(this.baseUrl + "api/rhymeSearch?word="+ this.keyword+ "&numString="+ this.str1056)
          .then(res => {
            console.log(res.data);
            this.resultData = res.data;
            loader.hide()
          })
          .catch(err => {
            console.error(err);
            loader.hide()
          })
    }
  }
}
</script>

<style lang="css" scoped>
.root{
  font-family: 'FZBIAOYSK--GBK1-0';
}

.navbar {
  -webkit-box-shadow: 0 8px 6px -6px #eee;
  -moz-box-shadow: 0 8px 6px -6px #eee;
  box-shadow: 0 8px 6px -6px #eee;
}

</style>